import React from 'react';
import TableBody from '../../atoms/TableBody';

interface Props {
    children?: JSX.Element | JSX.Element[];
    isExpandable?: boolean;
}
export default function Body({ children, isExpandable }: Props) {

    return (
        <>
            <TableBody>
                {children}
            </TableBody>
        </>
    )
}