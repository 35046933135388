/**
 * Hook to intialize browser window object
 * window object causes build to fail because it does not exist on SSR
 */
import React from 'react';
import useStore from './use-store';

const useWindow = () => {
    const { setServiceURL } = useStore();
    React.useEffect(() => {
        setServiceURL((typeof window !== undefined) ? window?.gbl_Service_URL : '');
    }, []);
};

export default useWindow;