import { createStore, TrebleGSM, useTreble } from 'treble-gsm';
import { IData } from './interfaces';

type TStoreActions = typeof actionKeys;
interface IDispatchers extends TrebleGSM.Dispatchers { }
interface IUtilities extends TrebleGSM.Utilities<TStoreActions> { }
interface IState {
    isInitial: boolean;
    activeScreen: 'search' | 'profile';
    searchQueries: string[];
    resultData: IData[];
    loading: boolean;
    error: null | object | string;
    activeShipNum: string | number | null;
    serviceURL: string;
}
const actionKeys = {
    ['updateActiveScreen']: 'updateActiveScreen',
    ['updateResultData']: 'updateResultData',
    ['updateSearchQueries']: 'updateSearchQueries',
    ['updateLoading']: 'updateLoading',
    ['updateError']: 'updateError',
    ['setIsInitial']: 'setIsInitial',
    ['updateActiveShipNum']: 'updateActiveShipNum',
    ['updateServiceURL']: 'updateServiceURL'
}
const Store = createStore([
    {
        action: actionKeys.setIsInitial,
        state: {
            isInitial: true
        }
    },
    {
        action: actionKeys.updateActiveScreen,
        state: {
            activeScreen: 'search'
        }
    },
    {
        action: actionKeys.updateSearchQueries,
        state: {
            searchQueries: []
        }
    },
    {
        action: actionKeys.updateResultData,
        state: {
            resultData: []
        }
    },
    {
        action: actionKeys.updateLoading,
        state: {
            loading: false
        }
    },
    {
        action: actionKeys.updateError,
        state: {
            error: null
        }
    },
    {
        action: actionKeys.updateActiveShipNum,
        state: {
            activeShipNum: null
        }
    },
    {
        action: actionKeys.updateServiceURL,
        state: {
            serviceURL: ''
        }
    }

]);

export const useTrebleStore = () => useTreble<IState, IDispatchers, IUtilities>();

export default Store;