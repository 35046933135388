import React from 'react';
import SearchScreen from '../SearchScreen';
import ShipmentProfileScreen from '../ShipmentProfileScreen';
import useStore from '../../../hooks/use-store';
import useWindow from '../../../hooks/use-window';

export default function SwitchScreens() {

    //makes sure window object is loaded and service url is saved to Store
    useWindow();

    const { activeScreen, loading, setIsInitial } = useStore();

    React.useEffect(() => {
        if (loading) {
            setIsInitial(false);
        }
    }, [loading]);

    return (
        <>
            {
                (activeScreen === 'search') ? <SearchScreen /> :
                    (activeScreen === 'profile') ? <ShipmentProfileScreen /> : null
            }
        </>
    )
}