import React from 'react';
import { useTrebleStore } from '../Store';
import { IData } from '../interfaces';
const useStore = () => {

    const [State, Store, Utils] = useTrebleStore();

    const isInitial = State.isInitial;
    const activeScreen = State.activeScreen;
    const resultData = State.resultData;
    const error = State.error;
    const loading = State.loading;
    const searchQueries = State.searchQueries;
    const activeShipNum = State.activeShipNum;
    const serviceURL = State.serviceURL;

    const setServiceURL = (url: string) => {
        Store.update(Utils.actions.updateServiceURL, url);
    }

    const setActiveShipNum = (activeShipNum: number | string) => {
        Store.update(Utils.actions.updateActiveShipNum, activeShipNum);
    }

    const goToShipmentProfile = (activeShipNum: number | string) => {
        setActiveShipNum(activeShipNum);
        Store.update(Utils.actions.updateActiveScreen, 'profile');
    };
    const goToShipmentSearch = () => {
        Store.update(Utils.actions.updateActiveScreen, 'search');
    };
    const setIsInitial = (isInitial: boolean) => {
        Store.update(Utils.actions.setIsInitial, isInitial);
    }
    const setResultData = (data: IData[]) => {
        Store.update(Utils.actions.updateResultData, data);
    }
    const setLoading = (isLoading: boolean) => {
        Store.update(Utils.actions.updateLoading, isLoading);
    }
    const setError = (error: null | string | object) => {
        Store.update(Utils.actions.updateError, error);
    }
    const setSearchQueries = (query: string[]) => {
        Store.update(Utils.actions.updateSearchQueries, query);
    }

    return {
        activeScreen,
        goToShipmentProfile,
        goToShipmentSearch,
        setResultData,
        resultData,
        error,
        loading,
        setError,
        setLoading,
        searchQueries,
        setSearchQueries,
        setIsInitial,
        isInitial,
        activeShipNum,
        setActiveShipNum,
        serviceURL,
        setServiceURL
    }
};

export default useStore;