const useDateTime = () => {

    const convertStringToUTC = (dateString: string) => {
        const utcDateFormat = (typeof dateString === 'string') ? new Date(dateString).toUTCString() : 'Invalid Date';
        if (utcDateFormat === 'Invalid Date') {
            return '--'
        }
        return utcDateFormat;
    }

    return {
        convertStringToUTC
    }
};

export default useDateTime;