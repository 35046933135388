import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { TStatus } from '../../../interfaces';

interface Props {
    status?: TStatus;
};

export default function StatusBadge({ status }: Props) {

    return (
        <>
            {
                (status === 'Closed') ?
                    <Badge bg={'secondary'}>Closed</Badge> :
                    (status === 'InTransit') ?
                        <Badge>In Transit</Badge> :
                        (status === 'Delivered') ?
                            <Badge bg={'Success'}>Delivered</Badge> :
                            (status === 'Assigned') ?
                                <Badge bg={'secondary'}>Assigned</Badge> : null
            }
        </>
    )
}