import React from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import Button from '../../atoms/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useInput from '../../../hooks/use-input';
import useStore from '../../../hooks/use-store';

export default function SearchControl() {

    const { setSearchQueries, loading } = useStore();
    const { bind, value, reset } = useInput('');
    const [shipNums, setShipNums] = React.useState([]);
    React.useEffect(() => {
        setShipNums(value.split(',').map((query: string) => (query.replaceAll(' ', ''))));
    }, [value]);
    return (
        <>
            <Form>
                <Col>
                    <Row>
                        <Col>
                            <Form.Group>
                                {/* <Form.Label htmlFor="trackShipmentsInput">Track Shipments</Form.Label> */}
                                <InputGroup className="mb-3" style={{ height: '45px' }}>
                                    <Form.Control id="trackShipmentsInput" type={'text'} placeholder="Search for Shipments" {...bind} />
                                    <Button
                                        icon={<i className="fa-solid fa-magnifying-glass"></i>}
                                        label={'Search'}
                                        disabled={loading}
                                        onClick={() => {
                                            setSearchQueries([... new Set(shipNums)]);
                                            reset();
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </>
    )
}