import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from '../../atoms/Card';
import Panel from '../../molecules/Panel';
import StatusBadge from '../../atoms/StatusBadge';
import ShipmentHistory from '../../organisms/ShipmentHistory/ShipmentHistory';
import Button from '../../atoms/Button';
import StatusProgress from '../../atoms/StatusProgress';
import PlaceHolder from '../../molecules/PlaceHolder';
import ErrorSplash from '../../molecules/ErrorSplash';
import { useNonInitialEffect } from '../../../hooks/use-non-initial-effect';
import useStore from '../../../hooks/use-store';
import { IProfileData, IResponseProfile } from '../../../interfaces';
import useFetch from '../../../hooks/use-fetch/use-fetch';
import useDateTime from '../../../hooks/use-date-time';
import useWindow from '../../../hooks/use-window';

export default function ShipmentProfileScreen() {

    const { goToShipmentSearch, activeShipNum, resultData, setActiveShipNum, serviceURL } = useStore();
    const [profileData, setProfileData] = React.useState<IProfileData>();
    const [shipNums] = React.useState<string[]>(resultData.map((item) => (item.OrderTrackingNumber)));
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    const dateTime = useDateTime();

    const { response, error, loading, post } = useFetch<IResponseProfile>(`${serviceURL}/shipmenttracking/searchshipmentdetails?shipmentTrackingNumber=`, {
        defaultRes: {
            success: false,
            message: '',
            shipmentTrackingDetails: {
                OrderTrackingNumber: '',
                Pieces: '',
                TotalWeight: 0,
                TrailerNumber: '',
                PickupFromDateTime: '',
                DeliveryFromTime: '',
                PickupToDateTime: '',
                DeliveryFromDateTime: '',
                DeliveryToDateTime: ''
            }
        }
    });

    React.useEffect(() => {
        post(`${activeShipNum}`);
        shipNums.map((item, index) => {
            if (item === activeShipNum) {
                setCurrentIndex(index);
            }
        });
        let currentIndex: number;
        shipNums.map((item) => {
            if (item === activeShipNum) {
                currentIndex = 0;
            }
        });
    }, [activeShipNum]);

    useNonInitialEffect(() => {
        console.log(response)
        if (response.success && response?.shipmentTrackingDetails) {
            setProfileData(response.shipmentTrackingDetails);
        };
    }, [response]);


    const ShipmentInfo = ({ loading }: { loading?: boolean }) => (
        <Row>
            <Col sm={'4'}>
                <h4 className={'mb-0'}>{activeShipNum}</h4>
            </Col>
            <Col className={'d-flex justify-content-end align-items-center'}>
                {
                    (loading) ?
                        <div className={'d-flex align-content-center'}>
                            <Spinner as={'span'} animation="border" size={'sm'} variant="primary" className={'mt-1'} />
                            <p className={'mb-0 ps-2'}>Fetching...</p>
                        </div> :
                        <>
                            <p className={'mb-0 pe-3'}><strong>Qty:</strong> {profileData?.Pieces}</p>
                            <p className={'mb-0 pe-3'}><strong>Gross Weight:</strong> {profileData?.TotalWeight} lbs</p>
                            <p className={'mb-0'}><strong>Trailer:</strong> {profileData?.TrailerNumber}</p>
                        </>
                }
            </Col>
        </Row>
    );

    return (
        <>

            <Col className={'pt-4'}>
                {
                    (error !== null && loading === false) ?
                        <>
                            <Row>
                                <Col className={'d-flex justify-content-between'}>
                                    <div>
                                        <Button
                                            variant={'bg-none'}
                                            onClick={() => post(`${activeShipNum}`)}
                                            icon={<i className="fa-solid fa-arrows-rotate"></i>}
                                            label={'Refresh'}
                                            disabled={loading}
                                            small
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            variant={'bg-none'}
                                            onClick={goToShipmentSearch}
                                            icon={<i className="fa-solid fa-arrow-left"></i>}
                                            disabled={loading}
                                            label={'Shipment Search'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-3'}>
                                    <Panel title={<h4 className={'mb-0'}>{activeShipNum}</h4>}>
                                        <Col>
                                            <ErrorSplash error={error} />
                                        </Col>
                                    </Panel>
                                </Col>
                            </Row>
                        </> :
                        <>
                            <Row>
                                <Col className={'d-flex justify-content-between'}>
                                    <div>
                                        <Button
                                            variant={'bg-none'}
                                            onClick={() => post(`${activeShipNum}`)}
                                            icon={<i className="fa-solid fa-arrows-rotate"></i>}
                                            label={'Refresh'}
                                            disabled={loading}
                                            small
                                        />
                                    </div>
                                    <div>
                                        {
                                            (shipNums[currentIndex - 1] !== undefined) ?
                                                <Button
                                                    variant={'bg-none'}
                                                    onClick={() => setActiveShipNum(shipNums[currentIndex - 1])}
                                                    className={'ms-2'}
                                                    icon={<i className="fa-solid fa-angles-left"></i>}
                                                    label={`${shipNums[currentIndex - 1]}`}
                                                    disabled={loading}
                                                    small
                                                /> : <Button disabled style={{ opacity: 0 }} label={`000000000`} className={'ms-2'} />
                                        }
                                        {
                                            (shipNums[currentIndex + 1] !== undefined) ?
                                                <Button
                                                    variant={'bg-none'}
                                                    onClick={() => setActiveShipNum(shipNums[currentIndex + 1])}
                                                    icon={<i className="fa-solid fa-angles-right"></i>}
                                                    className={'me-2'}
                                                    label={`${shipNums[currentIndex + 1]}`}
                                                    disabled={loading}
                                                    rightAlignIcon
                                                    small
                                                /> : <Button disabled style={{ opacity: 0 }} label={`000000000`} className={'me-2'} />
                                        }

                                    </div>
                                    <div>

                                        <Button
                                            variant={'bg-none'}
                                            onClick={goToShipmentSearch}
                                            icon={<i className="fa-solid fa-arrow-left"></i>}
                                            disabled={loading}
                                            label={'Shipment Search'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-3'}>
                                    <Panel title={<ShipmentInfo loading={loading} />}>
                                        <Col>
                                            <Row>
                                                {
                                                    (loading) ?
                                                        <>
                                                            <PlaceHolder variant='default' />
                                                        </> :
                                                        <>
                                                            <Col>
                                                                <p className={'mb-0'}><strong>Scheduled Pickup Date:</strong></p>
                                                                <p>{dateTime.convertStringToUTC(profileData?.PickupFromDateTime || '')}</p>
                                                                <h5><strong>Origin</strong></h5>
                                                                <Card variant='light'>
                                                                    {/* <p className={'mb-0'}><strong>{profileData?.OriginCity}</strong></p> */}
                                                                    <p className={'mb-0'}><small><em>{profileData?.OriginCity}, {profileData?.OriginState} {profileData?.OriginPostalCode}, {profileData?.OriginCountry}</em></small></p>
                                                                </Card>
                                                            </Col>
                                                            <Col className={'d-flex align-items-end'}>
                                                                <div style={{ width: '100%' }}>
                                                                    <div className={'d-flex justify-content-start mb-2'}>
                                                                        <StatusBadge status={(profileData?.Status) ? profileData.Status : 'Unassigned'} />
                                                                    </div>
                                                                    <p className={'mb-0'}><small><strong>Last in-Transit:</strong></small></p>
                                                                    <p className={'mb-0'}><small>{profileData?.LastKnownLocationstringTime}</small></p>
                                                                    <p><small><em>{profileData?.LastKnownLocation}</em></small></p>
                                                                    <StatusProgress status={(profileData?.Status) ? profileData.Status : 'Unassigned'} />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <p className={'mb-0'}><strong>Estimated Delivery Date:</strong></p>
                                                                <p>{dateTime.convertStringToUTC(profileData?.DeliveryFromDateTime || '')}</p>
                                                                <h5><strong>Destination</strong></h5>
                                                                <Card variant='light'>
                                                                    {/* <p className={'mb-0'}><strong>{profileData?.DestinationName}</strong></p> */}
                                                                    <p className={'mb-0'}><small><em>{profileData?.DestinationCity}, {profileData?.DestinationState} {profileData?.DestinationPostalCode}, {profileData?.DestinationCountry}</em></small></p>
                                                                </Card>
                                                            </Col>
                                                        </>
                                                }
                                            </Row>
                                        </Col>
                                    </Panel>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-3'}>
                                    <ShipmentHistory data={profileData?.ShipmentHistories || []} loading={loading} />
                                </Col>
                            </Row>
                        </>
                }

            </Col>
        </>
    )
}