import React from 'react';
import TableHead from '../../atoms/TableHead';

interface Props {
    children?: JSX.Element | JSX.Element[];
    hide?: boolean;
    className?: string;
    onMount?: (ref: React.MutableRefObject<any>) => void;
}
function Head({ children, hide, className, onMount }: Props) {

    return (
        <>
            <TableHead
                hide={hide}
                className={className}
                onMount={onMount}
            >
                {children}
            </TableHead>
        </>
    )
}

Head.displayName = 'Head';
export default Head;