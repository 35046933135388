import React from 'react';
import TableColumn from '../../atoms/TableColumn';

interface Props {
    onMount?: (ref: React.MutableRefObject<any>) => void;
    children?: JSX.Element | JSX.Element[] | string;
    width?: string;
    hide?: boolean;
}
export default function Column({ onMount, children, width, hide }: Props) {

    return (
        <>
            <TableColumn onMount={onMount} width={width} hide={hide}>
                {children}
            </TableColumn>
        </>
    )
}