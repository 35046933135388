import React from 'react';
import * as styles from './TableBody.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
}

export default function TableBody({ children }: Props) {

    return (
        <>
            <tbody className={`${styles.compContainer} ${styles.strippedRows}`}>
                {children}
            </tbody>
        </>
    )
}