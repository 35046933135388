import React from 'react';

interface Props {
    width?: string;
    hide?: boolean;
    className?: string;
    children?: JSX.Element | JSX.Element[] | string;
    colSpan?: number;
}
export default function TableCell({ width, hide, className, children, colSpan }: Props) {

    return (
        <>
            <td style={{ width: width }} className={`${(hide) ? 'd-none' : ''} ${className}`} colSpan={colSpan}>
                {children}
            </td>
        </>
    )
}