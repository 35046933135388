import React from 'react';
import * as styles from './TableHead.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    hide?: boolean;
    className?: string;
    onMount?: (ref: React.MutableRefObject<any>) => void;
}

export default function TableHead({ children, hide, className, onMount }: Props) {

    const ref = React.useRef<any>(null);

    //Allows for the comp ref to be saved to a scoped store or other actions dependent on the component mounting
    React.useLayoutEffect(() => {
        if (onMount) {
            onMount(ref);
        }
    }, [ref]);

    return (
        <>
            <thead ref={ref} className={`${styles.compContainer} ${(hide) ? 'd-none' : ''} ${className}`}>
                {/**Generates column table headers */}
                <tr>
                    {children}
                </tr>
            </thead>
        </>
    )
}