import React from 'react';
import LoadingIndicator from '../../atoms/LoadingIndicator';

interface Props {
    text: string | string[];
}
export default function LoadingSplash({ text }: Props) {

    return (
        <>
            <div className={'d-flex justify-content-center align-items-center'} style={{ width: '100%', height: '250px' }}>
                <div className={'d-flex flex-column align-items-center'}>
                    <LoadingIndicator size={4} />
                    <p className={'pt-3'}><small>Searching Shipments...</small></p>
                    <p><em>{text}</em></p>
                </div>
            </div>
        </>
    )
}