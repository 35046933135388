import React from 'react';
import { default as TableAtom } from '../../atoms/Table';

export interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
    hover?: boolean;
    striped?: boolean;
    stickyColumns?: boolean;
    onMount?: (ref: React.MutableRefObject<any>) => void;
}
export default function Table({ children, hover, striped, onMount, stickyColumns, className }: Props) {

    const [isTableHeadNested] = React.useState((React.Children.toArray(children).find((child) => (child as any)?.type?.displayName === 'Head')) ? true : false);

    return (
        <>
            <TableAtom hover={hover} striped={striped} onMount={onMount} stickyColumns={stickyColumns} hasTableHead={isTableHeadNested} className={className}>
                {children}
            </TableAtom>
        </>
    )
}