import React from 'react';
import { Button as BSButton } from 'react-bootstrap';
import * as styles from './Button.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[] | string;
    label?: string;
    icon?: JSX.Element;
    variant?: 'basic' | 'bg-none';
    disabled?: boolean;
    onClick?: (e: any) => void;
    className?: string;
    small?: boolean;
    style?: React.CSSProperties;
    tabIndex?: number;
    rightAlignIcon?: boolean;
}
export default function Button({ children, label, icon, variant, onClick, disabled, className, small, style, tabIndex, rightAlignIcon }: Props) {

    const btnVariant = (variant === 'bg-none') ? 'link' : 'primary';
    const btnSize = (small && variant !== 'bg-none') ? 'sm' : undefined;

    return (
        <>
            <BSButton tabIndex={tabIndex} variant={btnVariant} onClick={onClick} disabled={disabled} className={`${styles.compContainer} ${(variant === 'bg-none') ? styles.bgNone : styles.basic} ${className}`} size={btnSize} type={'button'} style={style}>
                <>
                    <p className={`mb-0`}>
                        {
                            (icon && !rightAlignIcon) ?
                                <span className={`${styles.icon} pe-2`}>
                                    {icon}
                                </span> : null
                        }
                        {
                            (children) ? children : label
                        }
                        {
                            (icon && rightAlignIcon) ?
                                <span className={`${styles.icon} ps-2`}>
                                    {icon}
                                </span> : null
                        }
                    </p>
                </>
            </BSButton>
        </>
    )
}