import React from 'react';

interface Props {
    error?: null | string | object;
}
export default function ErrorSplash({ error }: Props) {

    return (
        <>
            <div className={'d-flex justify-content-center align-items-center'} style={{ width: '100%', height: '250px' }}>
                <div className={'d-flex flex-column align-items-center'}>
                    <i className="fad fa-exclamation-triangle text-primary" style={{ fontSize: '5rem' }}></i>
                    <h5 className={'pt-3'}>Something Went Wrong!</h5>
                    <p><small>{(error) ? error.toString() : ''}</small></p>
                </div>
            </div>
        </>
    )
}