import React from 'react';
import { default as BSPlaceholder } from "react-bootstrap/Placeholder";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

interface Props {
    variant: 'table' | 'default'
};

export default function PlaceHolder({ variant }: Props) {

    return (
        <>
            {
                (variant === 'table') ?
                    <>
                        <BSPlaceholder as="div" animation="wave">
                            <Row>
                                <Col>
                                    <BSPlaceholder className={'w-100'} style={{ height: '35px' }} />
                                </Col>
                                <Col>
                                    <BSPlaceholder className={'w-100'} style={{ height: '35px' }} />
                                </Col>
                                <Col>
                                    <BSPlaceholder className={'w-100'} style={{ height: '35px' }} />
                                </Col>
                                <Col>
                                    <BSPlaceholder className={'w-100'} style={{ height: '35px' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-2'}>
                                    <BSPlaceholder className={'w-100'} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-2'}>
                                    <BSPlaceholder className={'w-100'} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-2'}>
                                    <BSPlaceholder className={'w-100'} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-2'}>
                                    <BSPlaceholder className={'w-100'} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'pt-2'}>
                                    <BSPlaceholder className={'w-100'} />
                                </Col>
                            </Row>
                        </BSPlaceholder>
                    </> :
                    <>
                        <Row>
                            <Col>
                                <BSPlaceholder animation="wave">
                                    <BSPlaceholder xs={6} />
                                </BSPlaceholder>
                                <BSPlaceholder animation="wave">
                                    <BSPlaceholder xs={7} /> <BSPlaceholder xs={4} /> <BSPlaceholder xs={4} />{' '}
                                    <BSPlaceholder xs={6} /> <BSPlaceholder xs={8} />
                                </BSPlaceholder>
                            </Col>
                        </Row>
                    </>
            }
        </>
    )
}