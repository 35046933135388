import React from 'react';
import { default as BTCard } from 'react-bootstrap/Card';
import * as styles from './Card.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    variant?: 'light' | 'white';
    title?: string | JSX.Element;
    noPadding?: boolean;
    className?: string;
};

export default function Card({ children, variant, title, noPadding, className }: Props) {

    return (
        <>
            <BTCard className={`${styles.compContainer} ${(variant === 'light') ? styles.light : styles.white} ${className}`}>
                {
                    (title) ?
                        <BTCard.Header className={`${styles.header} py-3`}>
                            {
                                (typeof title === 'string') ? <h4 className={'mb-0'}>{title}</h4> : <>{title}</>
                            }
                        </BTCard.Header> : <></>
                }
                <BTCard.Body className={(noPadding) ? 'p-0' : ''}>
                    {children}
                </BTCard.Body>
            </BTCard>
        </>
    )
}