import React from 'react';
import Card from '../../atoms/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import StatusBadge from '../../atoms/StatusBadge';
import Button from '../../atoms/Button';
import { TStatus } from '../../../interfaces';
import * as styles from './ResultCard.module.scss';

interface Props {
    shippingNum: string;
    lastKnown: string;
    status: TStatus;
    palletQty: string | number;
    pu: string;
    del: string;
    carrier: string;
    weight: string | number;
    org: string;
    dest: string;
    onClick: () => void;
}
export default function ResultCard({ shippingNum, lastKnown, status, palletQty, pu, del, carrier, weight, org, dest, onClick }: Props) {

    return (
        <>
            <Card>
                <Row>
                    <Col>
                        <div className={'d-flex'}>
                            <h3 className={`${styles.link}`}><a onClick={onClick}>{shippingNum}</a></h3>
                            <div className={'ps-2'}>
                                <StatusBadge status={status} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={'d-flex justify-content-between'}>
                    <Col>
                        <p className={'mb-1'}><strong>Last Known Location:</strong> {lastKnown}</p>
                        <p className={'mb-1'}><strong>Status:</strong> {status}</p>
                        <p className={'mb-1'}><strong>Qty:</strong> {palletQty}</p>
                    </Col>
                    <Col>
                        <p className={'mb-1'}><strong>Pickup:</strong> {pu}</p>
                        <p className={'mb-1'}><strong>Delivery:</strong> {del}</p>
                        <p className={'mb-1'}><strong>Carrier Pro:</strong> {carrier}</p>
                    </Col>
                    <Col>
                        <p className={'mb-1'}><strong>Weight:</strong> {weight} lbs</p>
                        <p className={'mb-1'}><strong>Origin:</strong> {org}</p>
                        <p className={'mb-1'}><strong>Destination:</strong> {dest}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className={'pt-3'}>
                        <div>
                            <Button small label={'View More'} onClick={onClick} />
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}