import React from 'react';
import TableBS from 'react-bootstrap/Table';
import * as styles from './Table.module.scss';

export interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
    hover?: boolean;
    striped?: boolean;
    stickyColumns?: boolean;
    onMount?: (ref: React.MutableRefObject<any>) => void;
    hasTableHead?: boolean;
}
export default function Table({ children, className, hover, striped, onMount, stickyColumns, hasTableHead }: Props) {

    const ref = React.useRef<any>(null);

    //Allows for the comp ref to be saved to a scoped store or other actions dependent on the component mounting
    React.useLayoutEffect(() => {
        if (onMount) {
            onMount(ref);
        }
    }, [ref]);

    return (
        <>
            <div className={`${styles.compContainer} ${(stickyColumns) ? styles.stickyColumns : ''}`}>
                <TableBS
                    ref={ref}
                    className={`${styles.table} ${(hasTableHead) ? '' : styles.noTableHead} ${className}`}
                    bordered
                    hover={hover}
                    striped={striped}
                >
                    {children}
                </TableBS>
            </div>
        </>
    )
}