import React from 'react';
import SearchControl from '../../organisms/SearchControl';
import SearchResults from "../../organisms/SearchResults";
import { IResponse } from '../../../interfaces';
import useStore from '../../../hooks/use-store';
import useFetch from '../../../hooks/use-fetch/use-fetch';
import { useNonInitialEffect } from '../../../hooks/use-non-initial-effect';

export default function SearchScreen() {

    const { setResultData, setLoading, setError, searchQueries, serviceURL } = useStore();

    const { response, error, loading, post } = useFetch<IResponse>(`${serviceURL}/shipmenttracking/searchtrackingnumbers?trackingNumbers=`, {
        defaultRes: {
            success: false,
            message: '',
            shipmentTrackingResult: []
        }
    });

    useNonInitialEffect(() => {
        post(`${searchQueries.map((shipNum) => (shipNum))}`);
    }, [searchQueries]);

    useNonInitialEffect(() => {
        if (response.success) {
            setResultData(response.shipmentTrackingResult);
        } else {
            setResultData([]);
        }
    }, [response]);

    useNonInitialEffect(() => {
        setLoading(loading);
    }, [loading]);

    useNonInitialEffect(() => {
        setError(error);
    }, [error]);

    return (
        <>
            <SearchControl />
            <SearchResults />
        </>
    )
}