import * as React from "react";
import Treble from 'treble-gsm';
import type { HeadFC } from "gatsby";
import '../styles/style.global.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SwitchScreens from "../components/templates/SwitchScreens";
import Store from '../Store';


const IndexPage = () => {

  return (
    <main>
      <Treble store={Store}>
        <Container>
          <Row>
            <Col className={'pt-3'}>
              <SwitchScreens />
            </Col>
          </Row>
        </Container>
      </Treble>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => (
  <>
    <title>Shipment Tracking | Protrans</title>
    <script src="https://kit.fontawesome.com/6d545d20f4.js" crossOrigin="anonymous"></script>
  </>
);
