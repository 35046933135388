import React from 'react';

export default function NoResultsSplash() {

    return (
        <>
            <div className={'d-flex justify-content-center align-items-center'} style={{ width: '100%', height: '250px' }}>
                <div className={'d-flex flex-column align-items-center'}>
                    <div>
                        <i className="fad fa-search text-info" style={{ fontSize: '5rem' }}></i>
                        <i className="fad fa-sad-tear text-info" style={{ fontSize: '5rem' }}></i>
                    </div>
                    <p className={'pt-3'}><small>Sorry, No Results Found.</small></p>
                </div>
            </div>
        </>
    )
}