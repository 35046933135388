import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResultCard from '../../molecules/ResultCard';
import LoadingSplash from '../../molecules/LoadingSplash';
import NoResultsSplash from '../../molecules/NoResultsSplash';
import ErrorSplash from '../../molecules/ErrorSplash';
import useStore from '../../../hooks/use-store';
import useDateTime from '../../../hooks/use-date-time';

export default function SearchResults() {

    const { goToShipmentProfile, loading, error, resultData, isInitial, searchQueries } = useStore();
    const dateTime = useDateTime();
    const searchingFor = searchQueries.map((shipNum, index) => (`${shipNum}${(searchQueries.length > (index + 1)) ? ', ' : ''}`));
    const searchResultsFor = resultData.map((shipNum, index) => (`${shipNum.OrderTrackingNumber}${(resultData.length > (index + 1)) ? ', ' : ''}`));

    return (
        <>

            {
                (loading) ?
                    <LoadingSplash text={searchingFor} /> :
                    (error !== null) ?
                        <ErrorSplash error={error} /> :
                        ((resultData?.length === 0 && !isInitial) || resultData[0]?.SearchType) ?
                            <NoResultsSplash /> :
                            <>
                                {
                                    (isInitial) ? <></> :
                                        <div className={'d-flex justify-content-between'}>
                                            <div>
                                                <p><small><strong>Search Results for:</strong> <em>{searchResultsFor?.map((shipNum) => (shipNum))}</em></small></p>
                                            </div>
                                            <div className={'d-flex align-items-end'}>
                                                <p><small><strong>Results:</strong> {resultData.length}</small></p>
                                            </div>
                                        </div>

                                }
                                <div className={'px-3'} style={{ height: '600px', overflowX: 'auto', borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6' }}>
                                    {
                                        resultData?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Col className={'pt-3'}>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <ResultCard
                                                                shippingNum={item?.OrderTrackingNumber || '--'}
                                                                lastKnown={item?.LastKnownLocation || '--'}
                                                                status={item?.Status || '--'}
                                                                palletQty={item?.Pieces || '--'}
                                                                pu={dateTime.convertStringToUTC((typeof item?.PickupFromDateTime === 'string') ? item?.PickupFromDateTime : '')}
                                                                del={dateTime.convertStringToUTC((typeof item?.DeliveryFromDateTime === 'string') ? item?.DeliveryFromDateTime : '')}
                                                                carrier={item?.CarrierProNumber || '--'}
                                                                weight={item?.TotalWeight || '--'}
                                                                org={`${item?.OriginCity || '--'}, ${item?.OriginState || '--'} ${item?.OriginPostalCode || '--'}, ${item?.OriginCountry || '--'}`}
                                                                dest={`${item?.DestinationCity || '--'}, ${item?.DestinationState || '--'} ${item?.OriginPostalCode || '--'}, ${item?.OriginCountry || '--'}`}
                                                                onClick={() => goToShipmentProfile(item?.OrderTrackingNumber)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </>
            }
        </>
    )
}