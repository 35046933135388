import React from 'react';
import { default as TableComp, Props } from './Table';
import Head from './Head';
import Body from './Body';
import Row from './Row';
import Cell from './Cell';
import Column from './Column';

export interface ITable {
    (props: Props): JSX.Element;
    Head: typeof Head;
    Body: typeof Body;
    Row: typeof Row;
    Cell: typeof Cell;
    Column: typeof Column;
}

const Table: ITable = (props) => (<TableComp {...props} />);
Table.Head = Head;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;
Table.Column = Column;

export default Table;