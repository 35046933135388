import React from 'react';
import * as styles from './TableColumn.module.scss';

interface Props {
    onMount?: (ref: React.MutableRefObject<any>) => void;
    children?: JSX.Element | JSX.Element[] | string;
    width?: string;
    hide?: boolean;
}
export default function TableColumn({ onMount, children, width, hide }: Props) {

    const ref = React.useRef<any>(null);

    //Allows for the comp ref to be saved to a scoped store or other actions dependent on the component mounting
    React.useLayoutEffect(() => {
        if (onMount) {
            onMount(ref);
        }
    }, [ref]);

    return (
        <>
            <th
                ref={ref}
                style={{ width: width, minWidth: width, verticalAlign: 'top' }}
                className={`${styles.compContainer} ${(hide) ? 'd-none' : ''}`}
            >
                <div style={{ height: '100%' }}>
                    {children}
                </div>
            </th>
        </>
    )
}