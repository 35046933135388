import React from 'react';
import TableCell from '../../atoms/TableCell';

interface Props {
    width?: string;
    hide?: boolean;
    className?: string;
    children?: JSX.Element | JSX.Element[] | string;
    colSpan?: number;
}
export default function Cell({ width, hide, className, children, colSpan }: Props) {

    return (
        <>
            <TableCell width={width} hide={hide} className={className} colSpan={colSpan}>
                {children}
            </TableCell>
        </>
    )
}