import React from 'react';
import TableRow from '../../atoms/TableRow';

interface Props {
    data?: {
        ptrui_cacheKey: number,
        ptrui_sortKey?: number,
        ptrui_isSelected?: boolean,
        [key: string]: any
    };
    forwardRef?: React.MutableRefObject<null>;
    children?: JSX.Element | JSX.Element[];
    className?: string;
    isActive?: boolean;
    isStripped?: boolean;
    selectable?: boolean;
    noTextHighlight?: boolean;
    onSelect?: (data: {
        ptrui_cacheKey: number,
        ptrui_sortKey?: number,
        ptrui_isSelected?: boolean,
        [key: string]: any
    }, isActive: boolean, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
}
export default function Row({ children, className, isActive, data, onSelect, selectable, noTextHighlight, forwardRef }: Props) {

    return (
        <>
            <TableRow
                forwardRef={forwardRef}
                className={className}
                isActive={isActive}
                data={data}
                onSelect={onSelect}
                selectable={selectable}
                noTextHighlight={noTextHighlight}
            >
                {children}
            </TableRow>
        </>
    )
}