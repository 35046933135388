import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { TStatus } from '../../../interfaces';

interface Props {
    status?: TStatus
};

export default function StatusProgress({ status }: Props) {


    return (
        <>
            {
                (status === 'Closed') ?
                    <ProgressBar striped animated now={0} /> :
                    (status === 'Unassigned') ?
                        <ProgressBar striped animated now={0} /> :
                        (status === 'InTransit') ?
                            <ProgressBar striped animated now={60} /> :
                            (status === 'Delivered') ?
                                <ProgressBar striped variant={'success'} now={100} /> :
                                (status === 'Assigned') ?
                                    <ProgressBar striped variant={'secondary'} animated={false} now={20} /> : null
            }

        </>
    )
}