import React from 'react';
import Card from '../../atoms/Card';

interface Props {
    title?: string | JSX.Element;
    noPadding?: boolean;
    children?: JSX.Element | JSX.Element[];
    className?: string;
};

export default function Panel({ title, children, noPadding, className }: Props) {

    return (
        <>
            <Card title={title} noPadding={noPadding} className={className}>
                <>
                    {children}
                </>
            </Card>
        </>
    )
}