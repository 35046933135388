import React from 'react';
import Table from '../../molecules/Table';
import Panel from '../../molecules/Panel';
import PlaceHolder from '../../molecules/PlaceHolder';
import { IShipmentHistory } from '../../../interfaces';
import useDateTime from '../../../hooks/use-date-time';
import * as styles from './ShipmentHistory.module.scss';

interface Props {
    data?: IShipmentHistory[];
    loading?: boolean;
}
export default function ShipmentHistory({ data, loading }: Props) {

    const dateTime = useDateTime();

    return (
        <>
            <Panel title={'Shipment History'} noPadding className={`pb-2`}>

                {
                    (loading) ?
                        <div className={'p-3'}>
                            <>
                                <PlaceHolder variant={'table'} />
                            </>
                        </div> :
                        <div className={styles.compContainer} style={{ maxHeight: '300px' }}>
                            <Table striped stickyColumns>
                                <Table.Head>
                                    <Table.Column>Date</Table.Column>
                                    <Table.Column>Status</Table.Column>
                                    <Table.Column>Location</Table.Column>
                                </Table.Head>
                                <Table.Body>
                                    {
                                        data?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Table.Row>
                                                    <Table.Cell>{dateTime.convertStringToUTC((typeof item?.ActualFinish === 'string') ? item.ActualFinish : '')}</Table.Cell>
                                                    <Table.Cell>{(item?.Activity) ? item.Activity : '--'}</Table.Cell>
                                                    <Table.Cell>{(item?.Location) ? item.Location : '--'}</Table.Cell>
                                                </Table.Row>
                                            </React.Fragment>
                                        ))
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                }
            </Panel>
        </>
    )
}